import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { format } from 'date-fns';
import { ReactComponent as ArrowBlue } from '../../../../assets/icons/arrow_blue.svg';

import { BackButtonContainer, Icon, TitleContainer } from './styles';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';
import { Body } from '../../../../components/Styles/Body';
import { Container } from '../../../../components/Container';
import { Title } from '../Title';

import api from '../../../../services/api';
import { ResumePanel } from '../ResumePanel';
import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';
import { DealsListComponent } from '../DealsListComponent';

export interface ICustomerSuccessCases {
  name?: string;
  id: string;
  operacao?: string;
  etapa?: string;
  amount: string;
  pass_date?: string;
  providence_status: string;
  category: string;
  hubspot_deal_id: string;
  observation?: string;
  hasActivities: boolean;
}

interface ICustomerSucess {
  user_id: string;
  type: string;
  user_name: string;
}

export function CustomerSuccessScheduleListing(): JSX.Element {
  const location = useLocation();

  const user_cs = location.state as ICustomerSucess;
  const [activitiesInADay, setActivitiesInADay] = useState(
    {} as IActivitiesInADayDTO,
  );

  const [customerSuccessInfo, setCustomerSuccessInfo] =
    useState<ICustomerSuccessCases[]>();

  const [currentItems, setCurrentItems] = useState<ICustomerSuccessCases[]>();

  useEffect(() => {
    api
      .get(
        `/schedule-cs?start_date=${format(
          new Date(),
          'yyyy-MM-dd',
        )}&end_date&user_id=${user_cs.user_id}`,
      )
      .then(response => {
        setActivitiesInADay(response.data);
      });

    api
      .get(`/schedule-cs/all-categorized-cases?user_id=${user_cs.user_id}`)
      .then(response => {
        response.data.amount = new Intl.NumberFormat('pt-BR', {
          style: 'currency',

          currency: 'BRL',
        }).format(Number(response.data.amount));

        setCustomerSuccessInfo(response.data);
        setCurrentItems(response.data);
      });
  }, [user_cs.user_id]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1NavigationNow>Gestor CS</H1NavigationNow>
      </Navegation>
      <Body>
        {user_cs.type === 'Manager' ? (
          <BackButtonContainer>
            <Link
              to={{
                pathname: '/payments/schedule/manager/customer-sucess',
              }}
            >
              <Icon>
                <ArrowBlue />
              </Icon>
              Voltar
            </Link>
          </BackButtonContainer>
        ) : (
          <BackButtonContainer>
            <Link
              to={{
                pathname: '/payments/schedule/manager/customer-sucess',
              }}
            >
              <Icon>
                <ArrowBlue />
              </Icon>
              Voltar
            </Link>
          </BackButtonContainer>
        )}
        {user_cs ? (
          <TitleContainer>
            <Title
              name="Casos - "
              nameStrong={user_cs.user_name}
              isShared={false}
              separatorWidth={0}
            />
          </TitleContainer>
        ) : (
          <TitleContainer>
            <Title
              name="Casos"
              nameStrong=""
              isShared={false}
              separatorWidth={0}
            />
          </TitleContainer>
        )}
        {user_cs.type === 'Manager' && customerSuccessInfo ? (
          <ResumePanel
            selectedCalendarPeriod="Dia"
            quantity_activities={activitiesInADay.quantity_activities}
            quantity_finished_activities={
              activitiesInADay.quantity_finished_activities
            }
            activities={activitiesInADay.activities}
            activitiesInADay={activitiesInADay}
            setActivitiesInADay={setActivitiesInADay}
            hubspotDeals={customerSuccessInfo}
            user_id={user_cs.user_id}
          />
        ) : (
          ''
        )}
        {customerSuccessInfo ? (
          <DealsListComponent customerSuccessInfo={customerSuccessInfo} />
        ) : (
          ''
        )}
      </Body>
    </Container>
  );
}
