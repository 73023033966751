import { AiOutlineCalculator } from 'react-icons/ai';
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import { IHubspotDeal } from '../../dtos/IHubspotDeal';
import { IResponse } from '../../dtos/IResponse';
import { Form, Div, Label, Input, Button, H2 } from '../../styles';
import api from '../../../../services/api';
import verifyProposal from '../../utils/verifyProposal';
import { useAuth } from '../../../../hooks/auth';

interface IProps {
  dealId: string;
  deal: IHubspotDeal;
  returnValues: IResponse;
  proposalPercentage: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setReturnValues: React.Dispatch<React.SetStateAction<IResponse>>;
  openLoan: () => void;
  brokerClient: boolean;
}

export const ReturnValuesComponent = ({
  dealId,
  deal,
  returnValues,
  proposalPercentage,
  handleChange,
  setReturnValues,
  openLoan,
  brokerClient,
}: IProps): JSX.Element => {
  const { user } = useAuth();

  const createScheduleAlert = useCallback((message: string) => {
    Swal.fire('Atenção!', message, 'warning');
  }, []);

  const handleCreateCalculations = useCallback(async () => {
    let validValue = true;

    if (deal.retake && proposalPercentage) {
      validValue = await verifyProposal(
        proposalPercentage,
        deal.proposalVariation
          ? deal.proposalVariation
          : returnValues.proposalVariation,
      );
    }

    if (validValue) {
      if (deal.product === 'FEDERAL') {
        const response = await api.post<IResponse>(
          'negociations/calculations/federal',
          {
            honorary: deal.honorary,
            proposal_percentage: proposalPercentage,
            date_base: deal.date_base,
            number_of_months: deal.number_of_months,
            value: deal.value,
            updated_value: deal.updated_value,
            highlighted_honorary: deal.highlighted_honorary,
            trf: deal.trf,
            tj: deal.tj,
            pss: deal.pss,
            updated_pss: deal.updated_pss,
            loa: deal.loa,
            system_of_process: deal.system_of_process,
            debitor: deal.debitor,
            updated_value_ipcae: deal.updated_value_ipcae,
            customer_main_value: deal.customer_main_value,
            lawyer_main_value: deal.lawyer_main_value,
            user_id: user.id,
            percentValue: deal.proposalVariation,
            nature: deal.nature,
            receipt_deadline: deal.receipt_deadline,
            partner_fund: deal.partner_fund,
            sucbential_honorary: deal.sucbential_honorary,
            superpreference: deal.superpreference,
            date_base_superpreference: deal.date_base_superpreference,
            product: deal.product,
            hubspot_proposal_value: deal.hubspot_proposal_value,
            retake: deal.retake,
            principal_value_creditor: deal.principal_value_creditor,
            principal_value_lawyer: deal.principal_value_lawyer,
            fees_value_creditor: deal.fees_value_creditor,
            fees_value_lawyer: deal.fees_value_lawyer,
            special_table: deal.special_table || returnValues.special_table,
            hubspot_deal_id: dealId,
            judicial_district: deal.judicial_district,
            broker: deal.broker,
            isRRAReduction: deal.isRRAReduction,
            line: deal.line,
            cpf: deal.cpf,
            last_proposal_percent: deal.last_proposal_percent,
            its_fees_value: deal.its_fees_value,
            reason: deal.reason,
            last_proposal_value: deal.lastProposalValue,
          },
        );

        if (Number(response.data.liquidValue) < 60000) {
          createScheduleAlert('VALOR FORA DO TARGET - NÃO SEGUIR A NEGOCIAÇÃO');
        }

        if (Number(response.data.proposalPercentageRRA) > 85) {
          createScheduleAlert(
            'Proposta ultrapassa o TETO absoluto de 85% do valor líquido!',
          );
        }

        const lastProposal = deal.lastProposalValue
          ? Math.round(
              ((Number(deal.lastProposalValue) /
                Number(response.data.liquidValue)) *
                100 +
                Number.EPSILON) *
                100,
            ) / 100
          : '';

        setReturnValues({
          ...response.data,
          percentLastProposal: `${lastProposal}%`,
          lastProposalValue: deal.lastProposalValue
            ? Number(deal.lastProposalValue).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : '',
          liquidValue:
            deal.product === 'FEDERAL'
              ? Number(response.data.liquidValue).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : response.data.liquidValue,
          liquidValueRRA:
            deal.product === 'FEDERAL'
              ? Number(response.data.liquidValueRRA).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : response.data.liquidValueRRA,
        });
      } else {
        const response = await api.post<IResponse>(
          'negociations/calculations/state',
          {
            honorary: deal.honorary,
            proposal_percentage: proposalPercentage,
            date_base: deal.date_base,
            number_of_months: deal.number_of_months,
            value: deal.value,
            updated_value: deal.updated_value,
            highlighted_honorary: deal.highlighted_honorary,
            trf: deal.trf,
            tj: deal.tj,
            pss: deal.pss,
            updated_pss: deal.updated_pss,
            loa: deal.loa,
            system_of_process: deal.system_of_process,
            debitor: deal.debitor,
            updated_value_ipcae: deal.updated_value_ipcae,
            customer_main_value: deal.customer_main_value,
            lawyer_main_value: deal.lawyer_main_value,
            user_id: user.id,
            percentValue: deal.proposalVariation,
            nature: deal.nature,
            receipt_deadline: deal.receipt_deadline,
            partner_fund: deal.partner_fund,
            sucbential_honorary: deal.sucbential_honorary,
            superpreference: deal.superpreference,
            date_base_superpreference: deal.date_base_superpreference,
            product: deal.product,
            hubspot_proposal_value: deal.hubspot_proposal_value,
            retake: deal.retake,
            principal_value_creditor: deal.principal_value_creditor,
            principal_value_lawyer: deal.principal_value_lawyer,
            months_tax: deal.months_tax,
            prec_status: deal.prec_status,
            cpf: deal.cpf,
            reason: deal.reason,
          },
        );

        if (Number(response.data.liquidValue) < 62000) {
          createScheduleAlert('VALOR FORA DO TARGET - NÃO SEGUIR A NEGOCIAÇÃO');
        }

        setReturnValues(response.data);
      }
    }
  }, [
    deal.retake,
    deal.proposalVariation,
    deal.product,
    deal.honorary,
    deal.date_base,
    deal.number_of_months,
    deal.value,
    deal.updated_value,
    deal.highlighted_honorary,
    deal.trf,
    deal.tj,
    deal.pss,
    deal.updated_pss,
    deal.loa,
    deal.system_of_process,
    deal.debitor,
    deal.updated_value_ipcae,
    deal.customer_main_value,
    deal.lawyer_main_value,
    deal.nature,
    deal.receipt_deadline,
    deal.partner_fund,
    deal.sucbential_honorary,
    deal.superpreference,
    deal.date_base_superpreference,
    deal.hubspot_proposal_value,
    deal.principal_value_creditor,
    deal.principal_value_lawyer,
    deal.fees_value_creditor,
    deal.fees_value_lawyer,
    deal.special_table,
    deal.judicial_district,
    deal.broker,
    deal.isRRAReduction,
    deal.line,
    deal.cpf,
    deal.last_proposal_percent,
    deal.its_fees_value,
    deal.reason,
    deal.lastProposalValue,
    deal.months_tax,
    deal.prec_status,
    proposalPercentage,
    returnValues.proposalVariation,
    returnValues.special_table,
    user.id,
    dealId,
    setReturnValues,
    createScheduleAlert,
  ]);
  return (
    <>
      <Form>
        <Div className="container">
          <Div className="two">
            <Label>
              Valor bruto atualizado
              <Input
                name="valueUpdate"
                value={returnValues.grossValue}
                disabled
              />
            </Label>
            <Label>
              Valor do honorário
              <Input name="valueFees" value={returnValues.feesValue} disabled />
            </Label>
          </Div>
          <Div className="two">
            <Label>
              Honorários contratuais
              <Input
                id="fees"
                name="honorary"
                onChange={handleChange}
                value={deal.honorary}
                placeholder="0 - 100%"
              />
            </Label>
            <Label>
              Imposto de renda
              <Input name="ir" value={returnValues.irraValue} disabled />
            </Label>
          </Div>
          <Div className="two">
            <Label>
              IR %
              <Input
                name="percent_ir"
                value={
                  returnValues.percentageIncomeTax
                    ? `${returnValues.percentageIncomeTax}%`
                    : ''
                }
                disabled
              />
            </Label>
            <Label className="last">
              PSS
              <Input
                name="pss"
                id="pss"
                value={returnValues.pssValue}
                disabled
              />
            </Label>
          </Div>
        </Div>
        {user.profile !== 'Hunter New' &&
        user.profile !== 'Hunter Líder' &&
        user.profile !== 'Hunter Teste' &&
        user.profile !== 'Gestores Hunter New' ? (
          <Div className="container">
            <Div className="two">
              <Label>
                Valor precatório líquido
                <Input
                  name="valuePrecatory"
                  value={returnValues.liquidValue}
                  disabled
                />
              </Label>
              <Label>
                Valor proposta
                <Input
                  name="valueProposal"
                  value={returnValues.proposalValue}
                  disabled
                />
              </Label>
            </Div>
            {deal.product !== 'FEDERAL' && deal.product ? (
              <>
                <Div className="two">
                  <Label>
                    Percentual
                    <Input
                      id="percentage"
                      name="porcentagem"
                      onChange={handleChange}
                      placeholder="0 - 100%"
                      value={proposalPercentage}
                    />
                  </Label>
                  <Label>
                    Hon. Sucumbenciais
                    <Input
                      name="valueProposal"
                      value={returnValues.sucbential_honorary}
                      disabled
                    />
                  </Label>
                </Div>
                <Div className="two">
                  <Label>
                    Superpreferência
                    <Input
                      name="valueProposal"
                      value={returnValues.superpreference}
                      disabled
                    />
                  </Label>
                  <Button type="button" onClick={handleCreateCalculations}>
                    <AiOutlineCalculator />
                    Calcular
                  </Button>
                </Div>
                <Div className="two">
                  {returnValues &&
                  returnValues.loan &&
                  returnValues.loan.loanData.length > 0 ? (
                    <Button type="button" onClick={openLoan}>
                      <AiOutlineCalculator />
                      Consignado
                    </Button>
                  ) : (
                    ''
                  )}
                </Div>
              </>
            ) : (
              <>
                <Div className="two">
                  <Label>
                    Percentual
                    <Input
                      id="percentage"
                      name="porcentagem"
                      onChange={handleChange}
                      placeholder="0 - 100%"
                      value={proposalPercentage}
                    />
                  </Label>
                  <Button type="button" onClick={handleCreateCalculations}>
                    <AiOutlineCalculator />
                    Calcular
                  </Button>
                </Div>
                <Div className="two">
                  {returnValues &&
                  returnValues.loan &&
                  returnValues.loan.loanData.length > 0 ? (
                    <Button type="button" onClick={openLoan}>
                      <AiOutlineCalculator />
                      Consignado
                    </Button>
                  ) : (
                    ''
                  )}
                </Div>
              </>
            )}
          </Div>
        ) : (
          <Div className="container">
            {deal.product !== 'FEDERAL' && deal.product ? (
              <>
                <Div className="two">
                  <Label>
                    Valor líquido
                    <Input
                      name="valuePrecatory"
                      value={returnValues.liquidValue}
                      disabled
                    />
                  </Label>
                  <Label>
                    Hon. Sucumbenciais
                    <Input
                      name="valueProposal"
                      value={returnValues.sucbential_honorary}
                      disabled
                    />
                  </Label>
                </Div>
                <Div className="two">
                  <Label>
                    Superpreferência
                    <Input
                      name="valueProposal"
                      value={returnValues.superpreference}
                      disabled
                    />
                  </Label>
                  <Button type="button" onClick={handleCreateCalculations}>
                    <AiOutlineCalculator />
                    Calcular
                  </Button>
                </Div>
              </>
            ) : (
              <Div className="two">
                <Label>
                  Valor líquido SIMULADO
                  <Input
                    name="valuePrecatory"
                    value={returnValues.liquidValue}
                    disabled
                  />
                </Label>
                <Button type="button" onClick={handleCreateCalculations}>
                  <AiOutlineCalculator />
                  Calcular
                </Button>
              </Div>
            )}
          </Div>
        )}
      </Form>
      {deal.product === 'FEDERAL' &&
      deal.product &&
      user.profile !== 'Calculadora broker' &&
      user.profile !== 'Calculadora' &&
      user.profile !== 'Calculadora Closer' ? (
        <>
          <H2>Valores de referencia para negociação</H2>
          <Form>
            <Div className="container">
              <Div className="two">
                <Label>
                  Valor liquido
                  <Input
                    name="valueUpdate"
                    value={returnValues.liquidValueRRA}
                    disabled
                  />
                </Label>
                <Label>
                  IR% (RRA)
                  <Input
                    name="valueFees"
                    value={
                      returnValues.percentageIncomeTaxRRA
                        ? `${returnValues.percentageIncomeTaxRRA}%`
                        : ''
                    }
                    disabled
                  />
                </Label>
              </Div>
              <Div className="two">
                <Label>
                  Valor RRA
                  <Input
                    name="valueFees"
                    value={returnValues.irraValueRRA}
                    disabled
                  />
                </Label>
                <Label>
                  Percentual de proposta RRA
                  <Input
                    name="valueFees"
                    value={
                      returnValues.proposalPercentageRRA
                        ? `${returnValues.proposalPercentageRRA}%`
                        : ''
                    }
                    disabled
                  />
                </Label>
              </Div>
              {deal.retake ? (
                <Div className="two">
                  <Label>
                    Valor ultima proposta
                    <Input
                      name="valueFees"
                      value={returnValues.lastProposalValue}
                      disabled
                    />
                  </Label>
                  <Label>
                    % ultima proposta
                    <Input
                      name="valueFees"
                      value={returnValues.percentLastProposal}
                      disabled
                    />
                  </Label>
                </Div>
              ) : (
                ''
              )}
            </Div>
          </Form>
        </>
      ) : (
        ''
      )}
      {(deal.product === 'FEDERAL' &&
        deal.product &&
        user.profile !== 'Calculadora broker' &&
        user.profile !== 'Calculadora') ||
      (deal.loa === 'LOA 2026' && !user.profile.includes('Closer')) ? (
        <>
          <H2>Referência teto (APROVAÇÃO)</H2>
          <Form>
            <Div className="container">
              <Div className="two">
                <Label>
                  Teto RRA
                  <Input
                    name="valueUpdate"
                    value={
                      returnValues.rraCelling
                        ? `${returnValues.rraCelling}%`
                        : ''
                    }
                    disabled
                  />
                </Label>
                <Label>
                  Proposta Teto
                  <Input
                    name="valueFees"
                    value={
                      returnValues.tableCelling ? returnValues.tableCelling : ''
                    }
                    disabled
                  />
                </Label>
              </Div>
              <Div className="two">
                <Label>
                  % Entrada
                  <Input
                    name="valueFees"
                    value={
                      returnValues.percentProposalInput
                        ? `${returnValues.percentProposalInput}%`
                        : ''
                    }
                    disabled
                  />
                </Label>
              </Div>
            </Div>
          </Form>
        </>
      ) : (
        ''
      )}
    </>
  );
};
