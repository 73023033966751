import { ChangeEvent, useCallback, useState } from 'react';

import { ReactComponent as OrderCrescent } from '../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../assets/icons/polygon_down.svg';

import {
  Table,
  Thead,
  TableRow,
  Cell,
  ContainerTitle,
  OrderContainerIcon,
  Tbody,
  BoxTable,
  FiltersContainer,
  FilterContent,
  Select,
  AddDescriptionButton,
} from './styles';

import { Body } from '../../../../components/Styles/Body';
import { Container } from '../../../../components/Container';

export interface ICustomerSuccessCases {
  name?: string;
  id: string;
  operacao?: string;
  etapa?: string;
  amount: string;
  pass_date?: string;
  providence_status: string;
  category: string;
  hubspot_deal_id: string;
  observation?: string;
  hasActivities: boolean;
}

interface ICustomerSucess {
  customerSuccessInfo: ICustomerSuccessCases[];
}

export function DealsListComponent({
  customerSuccessInfo,
}: ICustomerSucess): JSX.Element {
  const [currentItems, setCurrentItems] =
    useState<ICustomerSuccessCases[]>(customerSuccessInfo);

  const [isOrder, setIsOrder] = useState(false);

  const orderInfoCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = currentItems.sort(
        (previous: ICustomerSuccessCases, next: ICustomerSuccessCases) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
            case 'operation':
              return previous.operacao > next.operacao
                ? 1
                : previous.operacao < next.operacao
                ? -1
                : 0;
            case 'amount':
              return previous.amount > next.amount
                ? 1
                : previous.amount < next.amount
                ? -1
                : 0;
            case 'stage':
              return previous.etapa > next.etapa
                ? 1
                : previous.etapa < next.etapa
                ? -1
                : 0;

            /* case 'date':
              return previous.pass_date > next.pass_date
                ? 1
                : previous.pass_date < next.pass_date
                ? -1
                : 0;
              break; */
            case 'category':
              return previous.etapa > next.etapa
                ? 1
                : previous.etapa < next.etapa
                ? -1
                : 0;
            default:
              return null;
          }
        },
      );

      setCurrentItems([...order]);
    },
    [currentItems, isOrder, setCurrentItems],
  );

  const orderInfoCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = currentItems.sort(
        (previous: ICustomerSuccessCases, next: ICustomerSuccessCases) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
            case 'operation':
              return previous.operacao < next.operacao
                ? 1
                : previous.operacao > next.operacao
                ? -1
                : 0;
            case 'amount':
              return previous.amount < next.amount
                ? 1
                : previous.amount > next.amount
                ? -1
                : 0;
            case 'stage':
              return previous.etapa < next.etapa
                ? 1
                : previous.etapa > next.etapa
                ? -1
                : 0;
            /* case 'date':
              return previous.pass_date < next.pass_date
                ? 1
                : previous.pass_date > next.pass_date
                ? -1
                : 0;
              break; */
            case 'category':
              return previous.etapa < next.etapa
                ? 1
                : previous.etapa > next.etapa
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setCurrentItems([...order]);
    },
    [currentItems, isOrder, setCurrentItems],
  );

  const handleSelectChangeStage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setCurrentItems(customerSuccessInfo);
      } else {
        const filteredItems = customerSuccessInfo.filter(filterInfo => {
          return filterInfo.etapa === value;
        });

        setCurrentItems(filteredItems);
      }
    },
    [customerSuccessInfo],
  );

  const handleFormatAmount = useCallback(value => {
    const formatAmount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',

      currency: 'BRL',
    }).format(Number(value));

    return formatAmount;
  }, []);

  const handleRedirectToIndividualCsHistory = useCallback(
    (customerSuccess: ICustomerSuccessCases) => {
      window.open(
        `https://app.hubspot.com/contacts/7857327/record/0-3/${customerSuccess.hubspot_deal_id}`,
      );
    },
    [],
  );

  return (
    <Container>
      <Body>
        <FiltersContainer>
          <FilterContent>
            <span>Filtrar etapa</span>
            <Select defaultValue="" onChange={handleSelectChangeStage}>
              <option value="">Selecione a opção</option>
              <option value="Aguardando">Aguardando</option>
              <option value="FECHADO">Fechado</option>
              <option value="Em Andamento">Em andamento</option>
              <option value="Análise jurídica">Análise jurídica</option>
              <option value="Assinado">Assinado</option>
              <option value="Cartório agêndado">Cartório agêndado</option>
              <option value="Checkpoint">Checkpoint</option>
              <option value="Liberado para assinar">
                Liberado para assinar
              </option>
              <option value="Novo negócio">Novo negócio</option>
              <option value="Pagamento">Pagamento</option>
            </Select>
          </FilterContent>
        </FiltersContainer>
        <BoxTable>
          <Table>
            <Thead>
              <TableRow providenceStatus="Default">
                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('name')
                      : orderInfoCsByDesc('name')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Nome do credor</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('operation')
                      : orderInfoCsByDesc('operation')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Operação</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('amount')
                      : orderInfoCsByDesc('amount')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Valor</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('stage')
                      : orderInfoCsByDesc('stage')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Etapa</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>
              </TableRow>
            </Thead>
            <Tbody>
              {currentItems &&
                currentItems.map(customer_sucess => (
                  <TableRow
                    key={customer_sucess.id}
                    providenceStatus={customer_sucess.providence_status}
                  >
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      <span>{customer_sucess.name}</span>
                    </Cell>
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      <span>{customer_sucess.operacao}</span>
                    </Cell>
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      {handleFormatAmount(customer_sucess.amount)}
                    </Cell>
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      {customer_sucess.etapa}
                    </Cell>

                    {/* <Cell>{customer_sucess.pass_date}</Cell> */}

                    {customer_sucess.observation !== undefined ? (
                      <AddDescriptionButton>
                        <button type="button">!</button>
                      </AddDescriptionButton>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
            </Tbody>
          </Table>
        </BoxTable>
      </Body>
    </Container>
  );
}
