import { IHubspotDeal } from '../../dtos/IHubspotDeal';
import { Form, Div, Label, Input } from '../../styles';

interface IProps {
  deal: IHubspotDeal;
}
export const InformationProcess = ({ deal }: IProps): JSX.Element => {
  return (
    <Form>
      <Div className="container">
        <Div className="one">
          <Label>
            Data de expedição
            <Input
              id="date"
              name="date"
              value={deal.expedition_date}
              disabled
            />
          </Label>
        </Div>
        <Div className="two">
          <Label>
            TRF/TJ
            <Input
              id="TRF"
              name="trf"
              value={deal.trf ? deal.trf : deal.tj}
              setColor={deal && deal.trf && deal.trf === 'TRF2 - TESTE'}
            />
          </Label>
          <Label>
            Início do processo
            <Input
              id="init"
              name="init"
              value={deal.start_of_process}
              disabled
            />
          </Label>
        </Div>
        <Div className="two">
          <Label>
            Nº originários
            <Input
              id="amount_business"
              name="amount_business"
              value={deal.quantity_in_main_process}
              disabled
            />
          </Label>
          <Label>
            LOA
            <Input id="loa" name="loa" value={deal.loa} disabled />
          </Label>
        </Div>
      </Div>

      <Div className="container">
        <Div className="one">
          <Label className="last">
            Ente devedor
            <Input name="to" value={deal.debitor} disabled />
          </Label>
        </Div>
        <Div className="one">
          <Label>
            Motivo
            <Input id="reason" name="reason" value={deal.reason} disabled />
          </Label>
        </Div>
        <Div className="one">
          <Label>
            Vara
            <Input name="stick" value={deal.judicial_district} disabled />
          </Label>
        </Div>
      </Div>

      <Div className="container">
        <Label>
          Advogado
          <Input id="lawyer" name="lawyer" value={deal.lawyer_name} disabled />
        </Label>
        <Div className="two">
          <Label>
            Honorários destacados
            <Input
              id="fees"
              name="honorarios_destacados"
              value={deal.highlighted_honorary}
              disabled
            />
          </Label>
          <Label>
            Natureza do precatório
            <Input
              id="nature"
              name="natureza_precatorio"
              value={deal.nature}
              setColor={
                deal && deal.nature && deal.nature === 'OUTRAS NATUREZAS'
              }
            />
          </Label>
        </Div>
        {deal.product !== 'FEDERAL' && (
          <Div className="two">
            <Label>
              Condição de superpreferência
              <Input
                id="fees"
                name="honorarios_destacados"
                value={deal.ssp_condition}
                disabled
              />
            </Label>
          </Div>
        )}
      </Div>
    </Form>
  );
};
